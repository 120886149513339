<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.addTitle') }}</h1>
        <p>{{ $t('creator.firstLooking') }}</p>
        <b-form-input :state="title_state" aria-describedby="input-live-help input-live-feedback" v-model="title" class="mt-2" @keyup.enter="title.length > 10 ? save(): ''" :placeholder="$t('creator.titleCasting')"></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback" class="text-left">
          <span v-if="title.length <= 10">{{ $t('creator.10characteres') }}</span>
          <span v-else-if="title.length > 65">{{ $t('creator.65characteres') }}</span>
        </b-form-invalid-feedback>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" :disabled="!title_state" @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
        <small class="mt-2 w-100 text-center text-muted">{{ $t('creator.requiredCharacters') }}</small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import casting_calls_services from '@/services/casting_calls';

export default {
  name: 'stepTitle',
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
  },
  data() {
    return {
      title: '',
      profile: {},
      utils,
      steps: null,
      casting_call: {}
    }
  },
  created() {
    this.getData();
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    },
    title_state() {
      return this.title.length > 10 && this.title.length <= 65;
    }
  },
  methods: {
    getData() {
      if (this.has_slug) { 
        this.casting_call = this.$route.params.casting_call_data;
        this.title = this.casting_call.title;
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        if (this.steps.title) this.title = this.steps.title;
      }
    },
    save() {
      if (this.has_slug) { 
        const form_data = new FormData();
        form_data.append('title', this.title);
        casting_calls_services.updateCastingCall(this.casting_call.uuid, form_data).then((response) => {
          this.$emit('update_campaign', response.response);
          window.history.pushState({}, null, `/view-casting-call/${response.response.slug}`);
        });
      } else {
        this.steps['title'] = this.title;
        localStorage.setItem('steps', JSON.stringify(this.steps));
        this.$router.push({ name: 'steps_casting_call', params: {step: 'cover-image'}})
      }
    },
  }
}
</script>